<template>
  <div class="manage-users-settings-wrapper">
    <div class="form-wrapper">
      <div class="header-wrapper flex-row">
        <div :data-cy="headerCenterUserCy" class="header-center">
          <div :data-cy="headerTitleUserCy" class="header-title">{{headerTitleUserName}}</div>
          <b-icon-x
            class="header-cancel-button"
            v-on:click="$router.push('/')"
          ></b-icon-x>
        </div>
      </div>
      <div class="form-body-wrapper">
        <div class="users-wrapper">
          <div :data-cy="usersHeaderCy" class="users-wr-header">
            <b-row>
              <b-button
                :data-cy="addUserButtonCy"
                v-on:click="addUser"
                class="mr-2"
                variant="outline-secondary"
                >{{addUserButtonName}}</b-button
              >

              <b-button
                :data-cy="approvalUsersButtonCy"
                v-if="approvalUsers.length"
                v-on:click="openUsersApprovalModal"
                variant="outline-secondary"
                ><span class="mr-1 length">{{ approvalUsers.length }}</span
                >Pending accounts</b-button
              >
            </b-row>
            <span :data-cy="customFieldWrapperCy" class="ml-5 custom-field-wrapper">
              <b-input-group>
                <b-form-input
                  :data-cy="inputUserSearchCy"
                  class="custom-field custom-field-gray-bg"
                  v-model="searchTerm"
                  placeholder="Search for user names and emails..."
                >
                </b-form-input>
                <b-input-group-append>
                  <b-icon-search></b-icon-search>
                </b-input-group-append>
              </b-input-group>
            </span>
          </div>
          <b-table
            :data-cy="usersTableCy"
            id="users-table"
            hover
            :items="items"
            :fields="columns"
            dark
            :per-page="perPage"
            :current-page="currentPage"
            table-class="b-custom-table"
            :filter="searchTerm"
            sort-by="email"
            no-sort-reset
            sticky-header
          >
            <template v-slot:cell(editColumn)="data">
              <b-icon-pencil-square
                :data-cy="editUserCy"
                class="clickable"
                v-on:click="editUser(data.item)"
              ></b-icon-pencil-square>
            </template>
            <template v-slot:cell(deleteColumn)="data">
              <b-icon-trash-fill
                :data-cy="deleteUserCy"
                class="clickable"
                v-on:click="deleteUser(data.item)"
              ></b-icon-trash-fill>
            </template>
          </b-table>
          <b-pagination
            dark
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            aria-controls="users-table"
          ></b-pagination>
          <user-modal
            ref="userModal"
            :type="modalActionType"
            :isEdit="modalActionType === 'edit'"
            :title="userModalTitle"
            :user-obj="selectedUser"
          ></user-modal>
          <users-approval-modal
            ref="usersApprovalModal"
            :title="'Pending Accounts'"
            :items="approvalUsers"
          ></users-approval-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserModal from "@/components/users/UserModal.vue";
import UsersApprovalModal from "@/components/users/UsersApprovalModal.vue";
import Constants from "@/helpers/constants"

export default {
  name: "Users",
  components: { UserModal, UsersApprovalModal },
  data() {
    return {
      perPage: 7,
      currentPage: 1,
      modalActionType: "add",
      searchTerm: "",
      userModalTitle: "",
      selectedUser: {},

      headerCenterUserCy: Constants.HEADER_CENTER_USER_CY,
      headerTitleUserCy: Constants.HEADER_TITLE_USER_CY,
      headerTitleUserName: Constants.HEADER_TITLE_USER_NAME,

      customFieldWrapperCy: Constants.CUSTOM_FIELD_WRAPPER_CY,
      inputUserSearchCy: Constants.INPUT_USER_SEARCH_CY,

      approvalUsersButtonCy:Constants.APPROVAL_USERS_BUTTON_CY,

      usersHeaderCy: Constants.USERS_HEADER_CY,
      addUserButtonCy: Constants.ADD_USER_BUTTON_CY,
      addUserButtonName: Constants.ADD_USER_BUTTON_NAME,

      usersTableCy: Constants.USERS_TABLE_CY,
      editUserCy: Constants.EDIT_USER_CY,
      deleteUserCy: Constants.DELETE_USER_CY,
    };
  },
  validations: {},
  computed: {
    columns() {
      return this.$store.state.users.columns;
    },
    items() {
      return this.$store.state.users.users;
    },
    approvalUsers() {
      return this.$store.state.users.approvalUsers;
    }
  },
  beforeMount() {
    this.$store.dispatch("getUsersToBeApproved");
    this.$store.dispatch("getUsers");
  },
  mounted() {
    this.$store.commit("SHOW_HEADER", false);
  },
  beforeDestroy() {},
  methods: {
    openUsersApprovalModal() {
      this.$refs.usersApprovalModal.open();
      this.$refs.usersApprovalModal.init();
    },
    addUser() {
      this.userModalTitle = "Add User Account";
      this.modalActionType = "register";
      this.selectedUser = {};
      setTimeout(() => {
        this.$refs.userModal.open();
      }, 0);
    },
    editUser(user) {
      this.userModalTitle = "Edit User Account";
      this.modalActionType = "edit";
      this.selectedUser = user;
      setTimeout(() => {
        this.$refs.userModal.open();
      }, 0);
    },
    deleteUser(user) {
      const params = {
        modal: this.$bvModal,
        callback: this.deleteCallback.bind(this, user),
        title: "Confirm delete",
        text: `Are you sure you want to delete ${user.email}?`
      };
      this.$store.dispatch("showConfirmDialog", params);
    },
    async deleteCallback(user) {
      let response = await this.$store.dispatch("handleDeleteUser", user);
    }
  }
};
</script>

<style lang="scss">

.manage-users-settings-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/earth_background.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  .users-wrapper {
    .users-wr-header {
      margin: 10px 0px;
      display: flex;
      justify-content: space-between;
      button {
        font-size: 12px;
        display: flex;
        align-items: center;
        color: white;
        .length {
          width: 22px;
          height: 22px;
          align-items: center;
          display: flex;
          justify-content: center;
          color: white;
          font-weight: bold;
          border-radius: 50px;
          background-color: #0a78cc;
          padding: 3px 6px;
        }
      }
      .custom-field-wrapper {
        max-width: 300px;
        flex: 1;
        input {
          border-radius: 4px;
        }
        .input-group-append {
          position: absolute;
          color: white;
          right: 8px;
          top: 8px;
        }
      }
    }
    .b-table-sticky-header {
      max-height: 500px;
    }
    .b-custom-table {
      color: #fff;
      background-color: transparent;
      font-size: 12px;
      th {
        background-color: #212d38 !important;
        color: rgb(206, 214, 224) !important;
        border: none;
        &[aria-sort="ascending"],
        &[aria-sort="descending"] {
          color: white !important;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .b-pagination {
      font-size: 12px;
      span {
        color: white !important;
        border-color: #4b5660 !important;
        background-color: transparent !important;
      }
      button {
        color: white !important;
        border-color: #4b5660 !important;
        background-color: transparent !important;
        &.page-link {
          color: #007bff !important;
        }
      }
      .active {
        button {
          color: white !important;
          background-color: #007bff !important;
        }
      }
    }
  }

  .ml-auto {
    margin-left: auto !important;
  }
  .form-wrapper {
    border-radius: 8px;
    min-width: 600px;
    .invalid-tooltip {
      position: inherit;
    }
    .header-center {
      display: flex;
      margin-left: 24px;
      margin-right: 24px;
    }
    background-color: #212c38;
    overflow: hidden;
    .settings-button {
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 20px;
      width: auto;
      height: 30px;
    }
    .settings-button:hover {
      background-color: #fff !important;
      color: #0a78cc !important;
    }

    .form-body-wrapper {
      padding: 20px;
    }
    .storage-settings-form-input {
      width: 70%;
    }
    .databricks-save-button-wrapper {
      clear: both;
      margin-top: 0px;
    }
    .header-wrapper {
      width: 100%;
    }
    .inputs-wrapper {
      background-color: #212c38;
      padding: 10px;
      height: 300px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      .input-wr {
        display: flex;
        flex-direction: column;
        width: 300px;
        color: #e1ebf5;
        .form-group {
          margin-bottom: 5px;
          text-align: left;
        }
      }
    }
  }

  .field-spacer {
    clear: both;
    height: 10px;
  }
  .settings-label {
    width: 125px;
    color: #cfe2f2;
    font-size: 12px;
    margin-right: 12px;
    margin-top: 10px;
  }
  .settings-field {
    min-width: 100px;
    height: 26px;
    padding: 4px 4px 4px 5px;
    font-size: 12px;
    font-weight: 400;
    border: 0;
    margin-top: 8px;
  }
  .settings-field-long {
    width: 400px;
  }
  .settings-field:focus {
    border: none;
  }
  .settings-field-validate {
    border: 1px solid red !important;
  }
  .settings-field-blue-bg {
    background-color: rgb(33, 44, 56);
    border: 1px solid #4b5561;
    color: #fff !important;
  }
  .settings-field-blue-bg:focus {
    box-shadow: 0 0 0 0.2rem rgba(13, 150, 255, 1);
    border: none;
  }

  .nav-link {
    color: #777;
    background-color: #212c38;
    padding-top: 8px;
    font-size: 12px;
  }
  .nav-tabs .nav-link:hover {
    color: #0a78cc;
    border: 0px;
  }
  .nav-tabs .nav-link.active {
    background-color: #212c38 !important;
    color: #ddd !important;
    border-radius: 0;
    border-bottom: 0;
    padding-bottom: 12px;
  }
}
</style>
