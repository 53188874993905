<template>
  <div>
    <div class="">
      <span v-on:click="open()">{{ text }}</span>
    </div>
    <b-modal
      id="default-dialog"
      ref="usersApprovalModal"
      centered
      hide-footer
      :title="title"
    >
      <b-col class="p-0">
        <div class="custom-table p-0 mb-4">
          <div class="grid-header">
            <div class="grid-row grid-header-row">
              <div class="grid-cell grid-header-cell email-col">
                Email
              </div>
              <div
                class="grid-cell grid-header-cell grid-cell-action small-cell"
              >
                Approve
              </div>
              <div
                class="grid-cell grid-header-cell grid-cell-action small-cell"
              >
                Delete
              </div>
            </div>
          </div>
          <div class="grid-body">
            <div
              class="grid-row"
              v-for="entry in filteredItems"
              :key="entry.id"
            >
              <div class="grid-cell email-col" @click.prevent>
                {{ entry.email }}
              </div>
              <div class="grid-cell grid-cell-action small-cell" @click.prevent>
                <b-icon-check-circle
                  v-on:click="handleApprove(entry)"
                ></b-icon-check-circle>
              </div>
              <div class="grid-cell grid-cell-action small-cell" @click.prevent>
                <b-icon-trash-fill
                  v-on:click="handleReject(entry)"
                ></b-icon-trash-fill>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="p-0 footer mt-2">
        <b-button variant="primary" type="button" v-on:click="close">
          Done
        </b-button>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "UsersApprovalModall",
  components: {},
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Password Reset",
    },
  },
  data() {
    return {
      filteredItems: [],
    };
  },
  computed: {},
  methods: {
    init() {
      this.filteredItems = this.items;
    },
    close() {
      this.$refs.usersApprovalModal.hide();
    },
    open() {
      this.$refs.usersApprovalModal.show();
    },
    async handleApprove(user) {
      let response = this.$store.dispatch("handleApprove", user);
      if (response) {
        this.filteredItems = this.filteredItems.filter(
          (us) => us.id !== user.id
        );
      }
    },
    async handleReject(user) {
      let response = this.$store.dispatch("handleDeleteUser", user);
      if (response) {
        this.filteredItems = this.filteredItems.filter(
          (us) => us.id !== user.id
        );
      }
    },
  },
};
</script>
<style lang="scss">
.email-col {
  flex: 1;
  text-align: left;
}
</style>
